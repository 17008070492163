@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::selection{background:#0ebeff}::-moz-selection{background:#0ebeff}*{box-sizing:border-box}*:focus:not(.focus-visible){outline:none}html{overflow:hidden;height:100%}body,#root{width:100%;height:100%;margin:0;padding:0;zoom:90%;-webkit-touch-callout:none;-webkit-user-select:none;-ms-user-select:none;user-select:none}body{background:#1d1d1d;position:fixed}.root{display:flex;height:100%}
.grid{display:flex}@media(max-width: 1024px){.grid{flex-direction:row}}.grid-column{flex:1 1}@media(min-width: 1024px){.grid-column{flex-direction:row}}
.container{max-width:960px;padding:40px;margin:0 auto}.word-wrapper{white-space:nowrap;display:inline-flex}
