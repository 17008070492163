@import '../../constants/style/viewBreakpoints';

.grid {
  display: flex;
  @media (max-width: $break-point-1024) {
    flex-direction: row;
  }
}

.grid-column {
  flex: 1;
  @media (min-width: $break-point-1024) {
    flex-direction: row;
  }
}
