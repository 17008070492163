.container {
  max-width: 960px;
  padding: 40px;
  margin: 0 auto;
}

.word-wrapper {
  white-space: nowrap;
  display: inline-flex;
}
