@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@500&display=swap');

@import './constants/style/styles';

::selection {
  background: $selection-blue;
}
::-moz-selection {
  background: $selection-blue;
}

* {
  box-sizing: border-box;
}

*:focus:not(.focus-visible) {
  outline: none;
}

html {
  overflow: hidden;
  height: 100%;
}

body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  zoom: 90%;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  background: $background-grey;
  position: fixed;
}

.root{
  display: flex;
  height: 100%;
}
